import React from "react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-scroll";

const Home = () => {
  return (
    <div
      name="home"
      className="h-screen w-full bg-gradient-to-b from-black via-black to-gray-800 "
    >
      <div className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
        <div className="flex flex-col justify-center h-full">
          <h2 className="text-4xl sm:text-7xl font-bold text-white">
            I'm a Web Developer
          </h2>
          <p className="text-gray-50 py-4 max-w-md">
            My name is Yash Kumavat. I have 6 month self-learning experience of
            HTML, CSS, Javascript and React.js. I love to explore and learn new
            technologies and making projects. I like to code things from scratch and enjoy turning complex problems into simple, beautiful and intuitive interfaces.
          </p>
          <div>
            <Link
              to="projects"
              smooth
              duration={500}
              className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer"
            >
              Projects
              <span className="group-hover:rotate-90 duration-300">
                <MdOutlineKeyboardArrowRight size={25} className="ml-1" />
              </span>
            </Link>
          </div>
        </div>

        <div>
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJtTdMVa-UBhCNUGWMndzt7DYWNHwhJr3VoG5uhO0PPH_uBaLf"
            alt="my profile"
            className="rounded-2xl mx-auto w-2/3 md:w-full h-full object-fill 2xl:ml-9"
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
